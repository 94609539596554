<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Modules</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field columns">
          <div class="column is-half">
            <label class="label">Base Modules</label>
            <div v-for="(baseModule, index) in companyBaseModules"
              :key="index">
              <div class="columns">
                <div class="column is-two-thirds">{{ baseModule.modDesc }}</div>
                <div class="column is-one-third">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                    <input v-model="baseModule.enabled"
                      type="checkbox"
                      :disabled="!$userInfo.isSystemAccount">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Optional Modules</label>
            <div v-for="(optionalModule, index) in companyOptionalModules"
              :key="index">
              <div class="columns">
                <div class="column is-two-thirds">{{ optionalModule.modDesc }}</div>
                <div class="column is-one-third">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                    <input v-model="optionalModule.enabled"
                      type="checkbox"
                      :disabled="!$userInfo.isSystemAccount">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import StoreMixin from './storeMixin'
import { DeepDiff } from 'deep-diff'

export default {
  name: 'CompanyModulesSetting',
  mixins: [StoreMixin],
  data() {
    return {
      companyModules: {}
    }
  },
  computed: {
    companyBaseModules() {
      return this.companyModules?.length > 0 && this.companyModules?.filter(m => m.isBase)
    },
    companyOptionalModules() {
      return this.companyModules?.length > 0 && this.companyModules?.filter(m => !m.isBase)
    }
  },
  watch: {
    companyModules: {
      handler: function(val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyModules = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyModules' }))
    this.$hideSpinner()
  },
  methods: {
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateCompanyModules(value)
      }, this.debounceDelay)
    },
    async updateCompanyModules(modules) {
      if (!this.$userInfo.isSystemAccount) return
      if (!this.snapshotDiff) return

      for (let item of modules) {
        const [baseItem] = this.baseSnapshot.filter(i => i.flexiQuoteModuleId == item.flexiQuoteModuleId)
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      }
      this.companyModules = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanyModules', entity: modules }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateCompanyModules(this.companyModules)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>
